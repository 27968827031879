<template>
    <v-dialog
        v-model="dialog" 
        max-width="500px"
    >
        <template v-slot:activator="{ on }">
            <v-btn 
                fab
                x-small
                color="error" 
                v-on="on"
                width="18"
                height="18"
                @click="dialog=true"
            >
                <div style="font-size: 16px">!</div>
            </v-btn>
        </template>
        <v-card>
            <v-card-text>
                <template v-for="item in results">
                    <div :key="item.owner.regno_no">{{ $t('owner.title') }} {{ item.text }} {{ $t('urbanRenewal.info_error') }}</div>
                </template>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                color="primary"
                @click="dialog=false"
                >
                {{ $t('common.confirm') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        datas: {
            type: Array,
            default: [],
        },
    },

    data: vm => ({
        dialog: false,
        results: [],
    }),

    watch: {
        datas: {
            handler: function (val){
                this.initResults(val)
            },
            deep: true,
        }
    },
    mounted(){
        console.log(this.datas)
        this.initResults(this.datas)
    },
    methods: {
        initResults(data){
            this.results= []
            data.forEach(item=>{
                let owner
                if(item.building_relation){
                    owner = item.building_relation.owner
                } else {
                    owner = item.land_relation.owner
                }
                let text = owner.regno_no + ' ' + owner.owner_name
                let new_item = {
                    owner: owner,
                    text: text,
                }
                this.results.push(new_item)
            })
        }
    }

}
</script>
